import React, { useMemo, useEffect, useState } from "react";
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import * as web3 from '@solana/web3.js';
import { LogOut, X } from 'lucide-react';
import base58 from "bs58";

import { useWallet, WalletProvider, ConnectionProvider, useConnection } from '@solana/wallet-adapter-react';
import { WalletModalProvider, useWalletModal } from "@solana/wallet-adapter-react-ui";
import type { SolanaSignInInput } from '@solana/wallet-standard-features';
import { AutoConnectProvider } from "./components/AutoConnectProvider.tsx";
import * as buffer from "buffer";
import { BalanceContext } from "./context/Contexts.ts";
import Crash from "./pages/crash/+crash.tsx";
import { SocketProvider, useSocket, useSocketEmit, useSocketEvent } from "use-socket-io-react";
import toast, { Toaster } from "react-hot-toast";

require('@solana/wallet-adapter-react-ui/styles.css');

function App() {

    const network = process.env.REACT_APP_NETWORK === "mainnet-beta" ? "mainnet-beta" : "devnet";
    const wallets = useMemo(
        () => [],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    );
    if (!process.env.REACT_APP_SOLANA_RPC_URL) throw new Error("REACT_APP_SOLANA_RPC_URL not found")
    const endpoint = process.env.REACT_APP_SOLANA_RPC_URL;


    return (
        <SocketProvider uri={`ws://${process.env.REACT_APP_BACKEND_URL}`}>
            <AutoConnectProvider>
                <ConnectionProvider endpoint={endpoint}>
                    <WalletProvider wallets={wallets} autoConnect>
                        <WalletModalProvider>
                            <Toaster />
                            <Main />
                        </WalletModalProvider>
                    </WalletProvider>
                </ConnectionProvider>
            </AutoConnectProvider>
        </SocketProvider>

    );
}
export default App;

function Main() {
    const { publicKey, disconnect, signIn, sendTransaction } = useWallet();
    const { connection } = useConnection();
    const { emit } = useSocketEmit();
    const { isConnected, socket } = useSocket();
    const { setVisible } = useWalletModal();

    document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
            socket.disconnect()
        } else {
            socket.connect()
        }
    });

    const [pk, setPK] = useState("");
    const [pubkey, setPubKey] = useState("")

    useEffect(() => {
        if (publicKey?.toString() !== pk) {
            setPK(publicKey ? publicKey.toString() : "")
            setPubKey("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKey])

    useEffect(() => {
        if (isConnected && pk) {
            const previousLogin = () => {
                let blob = localStorage.getItem(pk)
                if (!blob) return false;
                let data = JSON.parse(blob);
                if (!('exp_time' in data) || !('signature' in data)) return false;
                if (new Date(new Date().getTime() + 1 * 60000) > new Date(data.exp_time)) return false;
                console.log("Reused login")
                emit("auth", [{ "pubkey": pk, "exp_time": data.exp_time, "signature": data.signature }]);
                return true;
            }
            if (!previousLogin()) {
                const signInFn = async () => {
                    console.log("Prompting login")
                    const input: SolanaSignInInput = {
                        domain: "yobet.lol",
                        expirationTime: new Date(new Date().getTime() + 2.5 * 86400000)
                            .toISOString() // 86400000ms per day
                    }
                    if (!signIn) throw new Error("Wallet doesnt support signIn")
                    const output = await signIn(input);
                    localStorage.setItem(output.account.address, JSON.stringify({ "exp_time": input.expirationTime, "signature": base58.encode(output.signature) }))
                    emit("auth", [{ "pubkey": output.account.address, "exp_time": input.expirationTime, "signature": base58.encode(output.signature) }]);
                }
                signInFn().catch((err) => { console.error(err) })
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected, pk])


    useSocketEvent<[string]>("auth", {
        handler(values) {
            setPubKey(values[0]);
        },
    })
    useEffect(() => {
        console.log("auth: ", pubkey)
    }, [pubkey])

    const [page, setPage] = useState("rocketride");

    const [balance, setBalance] = useState("");

    useSocketEvent<[number]>("balance", {
        handler(arg) {
            console.log("Balance: " + arg[0])
            setBalance((arg[0] / LAMPORTS_PER_SOL).toFixed(2));
        },
    })

    useSocketEvent<[string]>("toast", {
        handler(arg) {
            console.log("Server: " + arg[0])
            toast("Server: " + arg[0])
        },
    })

    useSocketEvent<[string]>("toast:error", {
        handler(arg) {
            console.error("Server: " + arg[0])
            toast.error("Server: " + arg[0])
        },
    })

    const [withdrawOpen, setWithdrawOpen] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState(0.5e9);

    const [depositOpen, setDepositOpen] = useState(false);
    const [depositAmount, setDepositAmount] = useState(1e9);
    const [depositSig, setDepositSig] = useState("");


    const deposit = async () => {
        const transaction = new web3.Transaction();
        if (!process.env.REACT_APP_BANK_PUBLIC_KEY) return console.error("Missing bank key")
        if (!pubkey) return console.error("Not authed")
        const senderPubKey = new web3.PublicKey(pubkey);
        const recipientPubKey = new web3.PublicKey(process.env.REACT_APP_BANK_PUBLIC_KEY);

        window.Buffer = buffer.Buffer;
        const sendSolInstruction = web3.SystemProgram.transfer({
            fromPubkey: senderPubKey,
            toPubkey: recipientPubKey,
            lamports: depositAmount,
        });

        transaction.add(sendSolInstruction);
        try {
            let sig = await sendTransaction(transaction, connection);
            console.log(sig)
            const latestBlockHash = await connection.getLatestBlockhash();
            await connection.confirmTransaction({
                blockhash: latestBlockHash.blockhash,
                lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
                signature: sig,
            }, "finalized")

            emit("deposit", [{ "signature": sig }]);
        } catch (e) {
            console.error(e);
        }
    }

    const withdraw = async () => {
        emit("withdraw", [{ "amount": withdrawAmount }]);
    }

    useSocketEvent<[string]>("withdrew", {
        handler(arg) {
            console.log("Withdrawal tx: " + arg[0])
            window?.open(`https://explorer.solana.com/tx/${arg[0]}`, '_blank')?.focus();
        },
    })

    const checkDeposit = async () => {
        emit("deposit", [{ "signature": depositSig }]);
    }

    return (
        <BalanceContext.Provider value={[balance]}>
            <nav className="flex flex-row items-center justify-between px-8 py-4">
                <div className="flex flex-row items-center gap-16">
                    <button onClick={() => { setPage("rocketride") }} className="flex flex-row items-center gap-2">
                        <svg className="h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 25" fill="none">
                            <rect y="0.5" width="13" height="24" fill="#2FB8D6" />
                            <rect x="19" y="0.5" width="13" height="24" fill="#2FB8D6" />
                        </svg>
                        <h1 className="text-2xl text-white font-akira">YOBET</h1>
                    </button>
                    <div className="flex flex-row gap-8">
                        <div className={`flex flex-row items-center gap-8 text-base ${page === "rocketride" ? "text-[#2FB8D6]" : "text-[#C4C4C4] hover:text-white"}`}>
                            <button onClick={() => { setPage("rocketride") }} className="">Rocket ride</button>
                        </div>
                        <div className={`flex flex-row items-center gap-8 text-base cursor-wait text-[#646464]`}>
                            <div className="">Staking (comming)</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                    {!pubkey ? (
                        <button onClick={() => { disconnect(); setVisible(true) }} className="flex flex-row items-center gap-2 px-4 py-2 text-white border border-[#28282D] hover:bg-[#28282D] rounded-md">
                            <div>Connect wallet</div>
                        </button>
                    ) : (
                        <>
                            <div className="text-[#2FB8D6] text-xl px-2 font-bold">{balance} <span className="text-[#C4C4C4] text-sm font-normal">SOL</span></div>
                            <div className="flex flex-row items-center gap-4 px-4 py-2 text-[#C4C4C4] border border-[#28282D] rounded-md">
                                <button onClick={() => { setDepositOpen(true) }} className="hover:text-white">Deposit</button>
                                <div className="w-[1px] h-6 bg-[#28282D]"></div>
                                <button onClick={() => { setWithdrawOpen(true) }} className="hover:text-white">Withdraw</button>
                            </div>
                            <div className="w-[1px] h-6 bg-[#28282D]"></div>
                            <button onClick={() => { localStorage.removeItem(pk); disconnect() }} className="flex flex-row items-center gap-2 px-4 py-2 text-white border border-[#28282D] hover:bg-[#28282D] rounded-md">
                                <div>{pubkey.slice(0, 3) + "..." + pubkey.slice(-3)}</div>
                                <LogOut color="white" size={16} />
                            </button>
                        </>
                    )}
                </div>
            </nav>
            <div className="h-[1px] w-screen bg-[#28282D]"></div>

            {page === 'rocketride' ? <Crash /> : ""}

            {
                depositOpen === true &&
                <>
                    <div onClick={() => { setDepositOpen(false) }} className="fixed top-0 left-0 w-full h-screen bg-[#19191b] opacity-50 "></div>

                    <div className="fixed top-0 left-0 z-20 grid w-full h-screen pointer-events-none place-content-center">
                        <div className="relative text-white bg-[#19191b] border border-[#28282D] rounded-lg z-20 pointer-events-auto">
                            <button className='absolute text-[#C4C4C4] right-0 p-4' onClick={() => { setDepositOpen(false) }}><X size={16} /></button>
                            <div className='flex flex-col gap-4 px-20 py-12'>
                                <h1 className='text-2xl font-akira text-[#2FB8D6]'>DEPOSIT</h1>
                                <div className="flex flex-col gap-2 w-96">
                                    <div className="flex flex-row justify-between">
                                        <div className="text-sm text-[#C4C4C4]">Deposit amount</div>
                                    </div>
                                    <div className="relative flex flex-row font-bold justify-between bg-[#28282D] rounded px-6 py-4">
                                        <input className="absolute top-0 left-0 w-full h-full px-6 py-4 bg-transparent grow" type="number" value={depositAmount / LAMPORTS_PER_SOL} onChange={e => { setDepositAmount(parseFloat(e.target.value) * LAMPORTS_PER_SOL) }} />
                                        <div>
                                            <span className="opacity-0">{depositAmount / LAMPORTS_PER_SOL}</span>
                                            <span className="text-xs font-normal opacity-100"> SOL</span>
                                        </div>
                                    </div>
                                </div>


                                <button className='commit-button' onClick={deposit}>Send Deposit</button>

                                <details>
                                    <summary className="cursor-pointer">
                                        Balance not showing up?
                                    </summary>
                                    <div className="h-2" />
                                    <div className="flex flex-col gap-2 w-96">
                                        <div className="flex flex-row justify-between">
                                            <div className="text-sm text-[#C4C4C4]">Verify tx signature</div>
                                        </div>
                                        <div className="relative flex flex-row font-bold justify-between bg-[#28282D] rounded px-6 py-4">
                                            <input className="absolute top-0 left-0 w-full h-full px-6 py-4 bg-transparent grow" value={depositSig} onChange={e => { setDepositSig(e.target.value) }} />
                                            <div>
                                                <span className="opacity-0">{depositSig}</span>
                                                <span className="text-xs font-normal opacity-100"></span>
                                            </div>
                                        </div>
                                        <button className='commit-button' onClick={checkDeposit}>Check tx</button>
                                    </div>

                                </details>
                            </div>
                        </div>
                    </div>

                </>
            }

            {
                withdrawOpen === true &&
                <>
                    <div onClick={() => { setWithdrawOpen(false) }} className="fixed top-0 left-0 w-full h-screen bg-[#19191b] opacity-50 "></div>

                    <div className="fixed top-0 left-0 z-20 grid w-full h-screen pointer-events-none place-content-center">
                        <div className="relative text-white bg-[#19191b] border border-[#28282D] rounded-lg z-20 pointer-events-auto">
                            <button className='absolute text-[#C4C4C4] right-0 p-4' onClick={() => { setWithdrawOpen(false) }}><X size={16} /></button>
                            <div className='flex flex-col gap-4 px-20 py-12'>
                                <h1 className='text-2xl font-akira text-[#2FB8D6]'>Withdraw</h1>
                                <div className="flex flex-col gap-2 w-96">
                                    <div className="flex flex-row justify-between">
                                        <div className="text-sm text-[#C4C4C4]">Withdraw amount</div>
                                        <div className="text-sm text-[#C4C4C4]">Min 0.1</div>
                                    </div>
                                    <div className="relative flex flex-row font-bold justify-between bg-[#28282D] rounded px-6 py-4">
                                        <input className="absolute top-0 left-0 w-full h-full px-6 py-4 bg-transparent grow" type="number" value={withdrawAmount / LAMPORTS_PER_SOL} onChange={e => { setWithdrawAmount(parseFloat(e.target.value) * LAMPORTS_PER_SOL) }} />
                                        <div>
                                            <span className="opacity-0">{withdrawAmount / LAMPORTS_PER_SOL}</span>
                                            <span className="text-xs font-normal opacity-100"> SOL</span>
                                        </div>
                                    </div>
                                </div>


                                <button className='commit-button' onClick={withdraw}>Withdraw</button>
                            </div>
                        </div>
                    </div>

                </>
            }
        </BalanceContext.Provider>
    );
}