import '../../OldApp.css';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { BalanceContext } from '../../context/Contexts.ts';
import { useSocketEvent, useSocketEmit, useSocket } from 'use-socket-io-react';
import toast from 'react-hot-toast';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';

let maxBet = LAMPORTS_PER_SOL * 1;

function Crash() {
    const { emit } = useSocketEmit()
    const { isConnected } = useSocket();
    const [balance] = useContext(BalanceContext);




    const [playing, setPlaying] = useState(false);
    const [gameState, setGameState] = useState<"starting" | "playing" | "crashed">("starting");
    const [stateArg, setStateArg] = useState(0);
    const [betAmount, setBetAmount] = useState(LAMPORTS_PER_SOL * 0.5);
    const [autoCashOut, setAutoCashOut] = useState(0);

    const [mainText, setMainText] = useState("");
    const [secondaryText, setSecondaryText] = useState("");
    const [recentMultipliers, setRecentMultipliers] = useState<number[]>([]);
    const recentMultipliersRef = useRef<number[]>([]);
    const [recentWins, setRecentWins] = useState<[string, number][]>([]);
    const recentWinsRef = useRef<[string, number][]>([]);
    const [numberPlaying, setNumberPlaying] = useState("");


    const { rive, RiveComponent } = useRive({
        src: 'rocket.riv',
        stateMachines: "crash",
        autoplay: true,
    });
    const animationMultiplier = useStateMachineInput(rive, "crash", "multiplier", 0);

    useEffect(() => {
        emit("crash:join", [])
    }, [emit, isConnected])

    useEffect(() => {
        if (gameState === "starting") {
            setMainText(`${(stateArg / 1000).toFixed(1)}s`)
            setSecondaryText("Starting")
        }
        if (gameState === "playing") {
            if (animationMultiplier) {
                animationMultiplier.value = stateArg / 1000
            }
            if (stateArg >= 1000) {
                setMainText(`${(Math.floor(stateArg / 10) / 100).toFixed(2)}x`)
            } else {
                setMainText("Go")
            }

            if (playing) {
                setSecondaryText(`+${(Math.floor(betAmount * stateArg / 10 / LAMPORTS_PER_SOL) / 100).toFixed(2)}sol`)
            } else {
                setSecondaryText("Multiplier")
            }
        }
        if (gameState === "crashed") {
            setMainText(`${(Math.floor(stateArg / 10) / 100).toFixed(2)}x`)
            setSecondaryText("Crashed")
        }
    }, [gameState, stateArg, animationMultiplier, playing, betAmount])

    const placeBet = () => {
        if (betAmount > maxBet) return toast.error("Exceeding max bet");
        if (betAmount > Number(balance) * LAMPORTS_PER_SOL) return toast.error("Insufficient balance");

        emit("crash:placeBet", [{
            bet: betAmount,
            auto_cashout: autoCashOut,
        }])

    }

    const cashOut = () => {
        emit("crash:cashOut", [])
    }

    useSocketEvent<[number, number]>("crash:placedBet", {
        handler(arg) {
            console.log("Bet placed: (crash)\nBet: " + arg[0] / LAMPORTS_PER_SOL + "\nAuto: " + arg[1] / 1000)
            setPlaying(true)
            setBetAmount(arg[0])
            setAutoCashOut(arg[1])
        },
    })

    useSocketEvent<[number]>("crash:activePlayers", {
        handler(arg) {
            setNumberPlaying(`${arg[0]}`)
        },
    })

    useSocketEvent<[number]>("crash:recentMultipliers", {
        handler(arg) {
            recentMultipliersRef.current = arg.concat(recentMultipliersRef.current).slice(0, 12)
            setRecentMultipliers(recentMultipliersRef.current.slice(0, 12))
        },
    })

    useSocketEvent<[string, number][]>("crash:recentWins", {
        handler(arg) {
            recentWinsRef.current = arg.concat(recentWinsRef.current).slice(0, 6)
            setRecentWins(recentWinsRef.current.slice(0, 6))
        },
    })

    useSocketEvent<[number]>("crash:won", {
        handler(arg) {
            setPlaying(false)
        },
    })

    useSocketEvent<[number]>("crash:playing", {
        handler(arg) {
            setMainText(`${(arg[0] / 1000).toFixed(2)}x`)
            setStateArg(arg[0])
            setGameState("playing")
        },
    })

    useSocketEvent<[number]>("crash:starting", {
        handler(arg) {
            setStateArg(arg[0]);
            setGameState("starting");
        },
    })

    useSocketEvent<[number]>("crash:crashed", {
        handler(arg) {
            setPlaying(false)
            setStateArg(arg[0])
            setGameState("crashed")
        },
    })

    return (
        <>
            <div className='h-16'></div>
            <div className="flex justify-center text-white">
                <div className='flex flex-row gap-16'>
                    <div className="flex flex-col gap-6">
                        <div className='text-3xl font-akira'><span className='text-[#2FB8D6]'>Rocket</span> Ride</div>

                        <div className="flex flex-col gap-4">
                            <div className={"flex flex-col gap-2 w-96 " + (playing ? "opacity-50" : "")}>
                                <div className="flex flex-col gap-2 w-96">
                                    <div className="flex flex-row justify-between">
                                        <div className="text-sm text-[#C4C4C4]">Bet amount</div>
                                        <div className="text-sm text-[#C4C4C4]">Max 1</div>
                                    </div>
                                    <div className="relative flex flex-row font-bold justify-between bg-[#28282D] rounded px-6 py-4">
                                        <input className="absolute top-0 left-0 w-full h-full px-6 py-4 bg-transparent grow" type="number" value={betAmount / LAMPORTS_PER_SOL} onChange={e => { setBetAmount(parseFloat(e.target.value) * LAMPORTS_PER_SOL) }} disabled={playing} />
                                        <div>
                                            <span className="opacity-0">{betAmount / LAMPORTS_PER_SOL}</span>
                                            <span className="text-xs font-normal opacity-100"> SOL</span>
                                        </div>
                                        <div className='z-20 flex gap-2 text-sm text-[#C4C4C4] font-normal'>
                                            <button onClick={() => { setBetAmount(betAmount / 2) }}>1/2</button>
                                            <button onClick={() => { setBetAmount(betAmount * 2) }}>2x</button>
                                            <button onClick={() => { setBetAmount(maxBet) }}>Max</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 w-96">
                                    <div className="flex flex-row justify-between">
                                        <div className="text-sm text-[#C4C4C4]">Auto cashout</div>
                                    </div>
                                    <div className="relative flex flex-row font-bold justify-between bg-[#28282D] rounded px-6 py-4">
                                        <input className="absolute top-0 left-0 w-full h-full px-6 py-4 bg-transparent grow" type="number" value={autoCashOut / 1000} onChange={e => { setAutoCashOut(Math.floor(parseFloat(e.target.value) * 1000)) }} disabled={playing} />
                                        <div>
                                            <span className="opacity-0">{autoCashOut / 1000}</span>
                                            <span className="text-xs font-normal opacity-100"> x</span>
                                        </div>
                                        <div className='z-20 flex gap-2 text-sm text-[#C4C4C4] font-normal'>
                                            <button onClick={() => { setAutoCashOut(0) }}>None</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(() => {
                                if (gameState === "starting" && !playing) {
                                    return <button onClick={placeBet} className='grid p-6 place-content-center font-akira bg-[#2FB8D6] rounded hover:bg-[#43a2b7]'>Place bet</button>
                                } else if ((gameState === "starting" || (gameState === "playing" && stateArg <= 1000)) && playing) {
                                    return <button className='grid p-6 place-content-center font-akira bg-[#8b3131] rounded'>Starting...</button>
                                } else if (gameState === "playing" && playing) {
                                    return <button onClick={cashOut} className='grid p-6 place-content-center font-akira bg-[#56CC7F] rounded hover:bg-[#3d9f5f]'>Cash out</button>
                                } else {
                                    return <button className='grid p-6 place-content-center font-akira bg-[#1f7183] rounded'>-</button>
                                }
                            })()}
                        </div>

                        <div className="flex flex-col gap-4 py-4">
                            <div className="flex items-center justify-between">
                                <div className='font-akira'><span className='text-[#2FB8D6]'>Recent</span> wins</div>
                                <div className='text-[#C4C4C4] text-xs'>{numberPlaying} playing</div>
                            </div>

                            {recentWins.map((x, i) => {
                                if (x[0]) return (
                                    <div key={i} className="flex items-center justify-between">
                                        <div className='text-sm text-[#C4C4C4]'>{x[0].slice(0, 6) + "..." + x[0].slice(-6)}</div>
                                        <div className='text-[#56CC7F]'>+<span className="font-bold">{(x[1] / LAMPORTS_PER_SOL).toFixed(2)} </span><span className="text-xs">SOL</span></div>
                                    </div>
                                );
                                return <></>;
                            }

                            )}
                        </div>

                    </div>
                    <div className='relative rounded-lg aspect-[1.5] h-[640px] overflow-hidden'>

                        <div className="absolute bottom-0 left-0 z-10 flex flex-row w-full gap-6 p-8 justify-evenly">
                            {recentMultipliers.map((x, i) => {
                                if (x > 2000) {
                                    return <div key={i} className='text-[#2FB8D6] font-bold'>{(x / 1000).toFixed(2)}x</div>
                                } else {
                                    return <div key={i} className='text-[#C4C4C4] font-bold'>{(x / 1000).toFixed(2)}x</div>
                                }
                            })}
                        </div>

                        <div className="absolute z-10 flex flex-col top-1/3 left-48">
                            <div className='font-akira text-[6rem] leading-none'>{mainText}</div>
                            <div className='text-2xl font-akira leading-none text-[#2FB8D6]'>{secondaryText}</div>
                        </div>


                        <RiveComponent className={"w-full h-full " + ((gameState !== "starting" && stateArg >= 1000) ? "opacity-100" : "opacity-0")} />



                        <img className='absolute top-0 left-0 w-full h-full -z-10' src="rocketRide_bg.png" alt="" />
                    </div>
                </div>


            </div >
        </>
    );
}
export default Crash;